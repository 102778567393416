import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { VideoPlayerVideoshotPopupState } from '@app/shared/components/video-player/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-video-player-videoshot-container',
  templateUrl: './video-player-videoshot-container.component.html',
  styleUrls: ['./video-player-videoshot-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerVideoshotContainerComponent {
  @Input() minDate!: Date;
  @Input() maxDate!: Date;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.form[loading ? 'disable' : 'enable']();
  }
  @Input() depthInHours!: number;
  @Input() state: VideoPlayerVideoshotPopupState;
  form: UntypedFormGroup = new UntypedFormGroup({
    dateFrom: new UntypedFormControl(null, Validators.required),
    duration: new UntypedFormControl('04:59')
  });
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  @Output() private createVideoshotTask: EventEmitter<{ dateFrom: Date, duration: number }> = new EventEmitter();
  @Output() private downloadVideoshot: EventEmitter<void> = new EventEmitter();
  @Output() private initVideoshotBuilding: EventEmitter<void> = new EventEmitter();
  private _loading: boolean;

  constructor(
    private resolution: ResolutionService
  ) { }

  get loading(): boolean {
    return this._loading;
  }

  onCreateVideoshotTask() {
    const { dateFrom, duration } = this.form.getRawValue();
    this.createVideoshotTask.emit({ dateFrom, duration: this.convertDuration(duration) });
  }

  onDownloadVideoshot() {
    this.downloadVideoshot.emit();
  }

  onInitVideoshotBuilding() {
    this.initVideoshotBuilding.emit();
  }

  /**
   * convert duration string mm:ss to seconds
   * @param durationString convert duration string mm:ss to seconds
   * @returns duration in seconds
   */
  private convertDuration(durationString: string): number {
    let duration: number;

    if (durationString === null) {
      duration = 299;
    } else {
      const splittedDuration: string[] = durationString.split(':');
      const durationMinutes = Number.parseInt(splittedDuration[0], 10) * 60;
      const durationSeconds = Number.parseInt(splittedDuration[1], 10);

      duration = durationMinutes + durationSeconds;
    }

    return duration;
  }
}
