import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/shared/components/snackbar';
import { RdvaPlaylistItem } from '@app/shared/entities/integrations';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';
import { AuthFacade } from '@app/views/auth/store';
import Hls, { ErrorTypes, Fragment } from 'hls.js';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { HlsFragParseHelper, HlsHelper, PlyrControlsHelper, PlyrTemplateHelper, PlyrTimeHelper, RdeaPlyr } from './helpers';
import { RdeaHls } from './helpers/rdea-hls';
import { VideoPlayerCustomClickEventType, VideoPlayerErrorTypes, VideoPlayerHlsError, VideoPlayerMode, VideoPlayerVideoshotRequest } from './models';
import { VideoPlayerHelperService } from './services';
import { VideoPlayerFacade } from './store';
import { VideoPlayerVideoshotPopupComponent } from './video-player-videoshot-popup';
import { MAX_FATALS_COUNT, TZ_OFFSET_IN_MINUTES } from './video-player.constants';
import { CameraApiService, RdaApiService } from '@app/shared/entities/rd';
import { catchError, debounceTime, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VideoPlayerHelperService, VideoPlayerFacade]
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  readonly VIDEO_CONTAINER_ID = PlyrTemplateHelper.VIDEO_CONTAINER_ID;

  @ViewChild(PlyrTemplateHelper.VIDEO_CONTAINER_ID, { static: false }) videoContainer: ElementRef;
  loading$: Observable<boolean>;
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  public releId: number = null;
  public rdaUid: string = null;
  public cameraRdaLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @Input() protected token: string;
  @Input() protected rdvaUri: string;
  @Input() protected set active(active: boolean) {
    this.mode = active ? VideoPlayerMode.LIVE : VideoPlayerMode.ARCHIVE;
  }
  @Input() protected cameraId: number;
  @Input() protected depthInHours: number;

  protected hls: RdeaHls;
  protected plyr: RdeaPlyr;
  protected minDate: Date;
  protected maxDate: Date;
  protected mode: VideoPlayerMode;
  protected recoverErrorTries = 0;
  protected hlsHelper: HlsHelper;
  protected controlsHelper: PlyrControlsHelper;
  protected unSubscribe$: Subject<boolean> = new Subject<boolean>();
  protected delayCameraInter$: Subject<void> = new Subject<void>();

  constructor(
    protected router: Router,
    protected dialog: MatDialog,
    protected authFacade: AuthFacade,
    protected snackbar: SnackbarService,
    protected resolution: ResolutionService,
    protected videoPlayerFacade: VideoPlayerFacade,
    protected videoPlayerHelper: VideoPlayerHelperService,
    protected rdaApiService: RdaApiService,
    protected translate: TranslateService,
    protected cameraApiService: CameraApiService,
  ) { }

  ngOnInit() {
    this.delayCameraInter$
      .pipe(
        debounceTime(0),
        takeUntil(this.unSubscribe$)
      ).subscribe(() => {
      this.initCameraConfig();
    });

    this.cameraApiService.getCameraRDAConfigById(this.cameraId)
      .pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        takeUntil(this.unSubscribe$),
      ).subscribe((res) => {
      this.cameraRdaLoader$.next(false);
      if (res instanceof HttpErrorResponse) {
        console.warn('The door does not have a config');
      } else {
        if (res.releId !== null && res.uid !== null) {
          this.releId = res.releId;
          this.rdaUid = res.uid;
        }
      }
      this.delayCameraInter$.next();
    });
  }

  ngOnDestroy() {
    if (this.plyr) {
      this.plyr.destroy();
    }

    if (this.hls) {
      this.hls.destroy();
      this.hlsHelper.destroy();
    }

    this.controlsHelper.destroy();
    this.videoPlayerFacade.clearState();

    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  /**
   * Create and configurate Plyr instance with loading state and helper
   */
  protected initPlyr() {
    // Create Plyr Instance
    this.plyr = new RdeaPlyr(this.mode, this.videoContainer.nativeElement, this.translate);

    // Init Plyr loading state and helpers
    this.loading$ = this.plyr.loadingState$.asObservable();
    this.controlsHelper = this.videoPlayerHelper.initControlsHelper(this.plyr);

    // Add listeners for custom events: change mode, selct playlist, get videoshot
    this.controlsHelper.addClickListener((type: VideoPlayerCustomClickEventType, source?: string) =>
      this.handlePlayerCustomControlsClick(type, source)
    );

    if (this.rdaUid !== null && this.releId !== null) { this.plyr.hasReleId = true; }
  }

  /**
   * Init video player store listeners (playlists loaded, current source selected)
   */
  protected initStoreListeners() {
    this.videoPlayerFacade.addPlaylistsLoadedListener((playlists: RdvaPlaylistItem[]) =>
      this.handlePlaylistsSuccessLoaded(playlists)
    );

    this.videoPlayerFacade.addSelectCurrentSourceListener((source: string) =>
      this.handleSourceSelection(source)
    );
  }

  /**
   * Load playlists for current mode
   */
  public loadPlaylists() {
    if (this.mode === VideoPlayerMode.ARCHIVE) {
      this.controlsHelper.seeking().addEventButtons();
      this.videoPlayerFacade.getPlaylists(this.rdvaUri, this.cameraId, -TZ_OFFSET_IN_MINUTES);
      return;
    }

    PlyrTimeHelper.clear();
    this.controlsHelper.seeking().removeEventButtons();
    this.videoPlayerFacade.getPlaylistsSuccess([{
      date: new Date().getTime(),
      playlist: this.videoPlayerHelper.getLiveSource(this.rdvaUri, this.cameraId)
    }]);
  }

  /**
   * Create and configurate Hls instance
   */
  protected initHls() {
    // Create Hls Instance
    this.hls = new RdeaHls(this.token, this.videoContainer.nativeElement);

    // Init Hls helper
    this.hlsHelper = this.videoPlayerHelper.initHlsHelper(this.hls);
  }

  /**
   * Add Hls helper event listeners for errors and manifest loaded
   */
  protected addHlsHelperListeners() {
    this.hlsHelper.addErrorListeners((error: VideoPlayerHlsError) =>
      this.handleHlsError(error)
    );

    this.hlsHelper.addManifestLoadedListener((fragments: Fragment[]) =>
      this.handleManifestLoaded(fragments)
    );
  }

  /**
   * Change video player mode
   */
  protected changeMode() {
    this.mode = this.mode === VideoPlayerMode.ARCHIVE ? VideoPlayerMode.LIVE : VideoPlayerMode.ARCHIVE;
    this.plyr.loadingState$.next(true);
    this.loadPlaylists();
  }

  /**
   * Open popup with videoshot download form
   */
  protected getVideoshot() {
    const data: DialogWrapperData<VideoPlayerVideoshotRequest, null> = {
      title: this.translate.instant('shared.video_player.video_shot.title'),
      componentName: 'GetVideoshot',
      body: {
        minDate: this.minDate,
        maxDate: this.maxDate,
        depthInHours: this.depthInHours,
        rdvaUri: this.rdvaUri,
        cameraId: this.cameraId,
        token: this.token
      }
    };

    this.dialog.open(VideoPlayerVideoshotPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '500px',
      autoFocus: false,
      data
    });
  }

  /**
   * Handle playlists loaded event
   * @param playlists rdva playlist items
   */
  protected handlePlaylistsLoaded(playlists: RdvaPlaylistItem[]) {
    if (!playlists?.length) {
      return;
    }

    this.controlsHelper.progress().clearCanvas();
    PlyrTimeHelper.sourceDate = playlists[playlists.length - 1].date;
    this.controlsHelper.playlists().preparePlaylistMenu(playlists);
    this.videoPlayerFacade.selectCurrentSource(playlists[playlists.length - 1].playlist);
  }

  /**
   * Handle hls error
   * @param param0 video player hls error params
   */
  protected handleHlsError({ fatal, type, details }: VideoPlayerHlsError) {
    if (type === VideoPlayerErrorTypes.AUTH_ERROR) {
      this.authFacade.logout(this.router.url);
      return;
    }

    if (!fatal) {
      return;
    }

    if (this.recoverErrorTries === MAX_FATALS_COUNT) {
      this.showFatalErrorMessage(type, details);

      if (this.active) {
        this.changeMode();
      }

      return;
    }

    switch (type) {
      case ErrorTypes.MEDIA_ERROR:
        this.hls.recoverMediaError();
        break;
      case ErrorTypes.NETWORK_ERROR:
        this.hls.startLoad();
        break;
    }

    this.recoverErrorTries++;
  }

  /**
   * Prepare fatal error message
   * @param errorType Hls error type
   * @param details Hls eror details
   */
  protected showFatalErrorMessage(errorType: string, details: string) {
    let reason: string;

    switch (errorType) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        reason = details === 'levelEmptyError'
          ? this.translate.instant('shared.video_player.message.error.reason.empty')
          : this.translate.instant('shared.video_player.message.error.reason.error')
        ;
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        reason = this.translate.instant('shared.video_player.message.error.reason.media');
        break;
      default:
        reason = this.translate.instant('shared.video_player.message.error.reason.unknown');
    }

    this.snackbar.showMessage(
      this.translate.instant('shared.video_player.message.error.reason.unknown', {
        text: reason
      })
    );
  }

  /**
   * Handle manifest loaded (prepare duration and fragments)
   * @param fragments Hls fragments
   */
  protected handleManifestLoaded(fragments: Fragment[]) {
    const { rangeTimestamps, eventTimestamps } = HlsFragParseHelper.extractDateRangesFromFragments(fragments);

    if (!rangeTimestamps?.length) {
      this.controlsHelper.resetControls();
      return;
    }

    PlyrTimeHelper.prepareProgressBarBounds(
      rangeTimestamps[0].startTimestamp, rangeTimestamps[rangeTimestamps.length - 1].endTimestamp
    );
    this.controlsHelper.progress().prepareAndDrawGaps(rangeTimestamps);
    this.controlsHelper.progress().prepareAndDrawEvents(eventTimestamps);
    this.controlsHelper.progress().setCurrentTime(rangeTimestamps[0].startTimestamp);
    this.controlsHelper.events().prepareEvents(eventTimestamps);

    if (!this.minDate) {
      this.minDate = this.videoPlayerHelper.getDateFromFragmentRelUrl(fragments[0].relurl, this.cameraId);
    }

    if (!this.maxDate) {
      this.maxDate = this.videoPlayerHelper.getDateFromFragmentRelUrl(fragments[fragments.length - 1].relurl, this.cameraId);
    }
  }

  /**
   * Handle source selection event
   * @param source video Uri
   */
  protected handleSourceSelection(source: string) {
    if (!source) {
      return;
    }

    // Reset min and max dates for archive downloading when source changed
    this.minDate = null;
    this.maxDate = null;

    if (this.hls) {
      this.hls.destroy();
      this.hlsHelper.destroy();
      this.initHls();
    } else {
      this.initHls();
    }

    this.addHlsHelperListeners();
    this.hls.loadSource(source);
  }

  /**
   * Handle clicks on custom buttons
   * @param type video player custim click event type
   */
  protected handlePlayerCustomControlsClick(type: VideoPlayerCustomClickEventType, source?: string) {
    switch (type) {
      case VideoPlayerCustomClickEventType.CHANGE_MODE:
        this.changeMode();
        break;
      case VideoPlayerCustomClickEventType.SELECT_PLAYLIST:
        this.handleSourceSelection(source);
        break;
      case VideoPlayerCustomClickEventType.GET_VIDEOSHOT:
        this.getVideoshot();
        break;
      case VideoPlayerCustomClickEventType.OPEN_DOOR:
        this.openDoor();
    }
  }

  /**
   * Handle successfully loaded playlists
   * @param {RdvaPlaylistItem[]} playlists rdva playlists items
   */
  protected handlePlaylistsSuccessLoaded(playlists: RdvaPlaylistItem[]) {
    if (playlists?.length !== 0) {
      this.plyr.initMode(this.mode);
      this.handlePlaylistsLoaded(playlists);
      return;
    }

    this.plyr.loadingState$.next(false);
    this.mode = VideoPlayerMode.LIVE;
  }

  protected openDoor(): void {
    this.rdaApiService.openDoor(this.rdaUid, this.releId).pipe(
      catchError((error: HttpErrorResponse) => of(error)),
      take(1)
    ).subscribe((response) => {
      if (response instanceof HttpErrorResponse) {
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.open.failed.text', {
            text: response.message
          }),
          'error'
        );
        return;
      }
      this.snackbar.showMessage(
        this.translate.instant('shared.video_player.message.open.success'),
        'success'
      );
    });
  }

  protected initCameraConfig(): void {
    this.initPlyr();
    this.initStoreListeners();
    this.plyr.loadingState$.next(true);
    this.loadPlaylists();
  }
}
