import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '@app/shared/api';
import {Account, Camera, KeysResponse, RdaResponse} from '@app/shared/entities/rd';
import {
  IntercomPanelConnectRequest,
  ServiceCamerasV2Response,
  ServiceCameraUpdateRequest,
  ServiceConnection,
  ServiceCreateConnectionRequest,
  ServiceCreateRequest,
  ServiceCreateResponse,
  ServiceEntranceFlatResponse,
  ServiceInfoResponse,
  ServiceIntercomConnectRequest,
  ServiceSignUpsResponse,
  ServicesList
} from '@app/shared/entities/rd/services/models';
import {Address, PagedResponse} from '@app/shared/models';
import {UpdateServiceRequest} from '@app/views/services/models';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Tariff} from '@app/shared/entities/rd/tariff';
import {ICommonDataProtocol, IQueryQualifier} from '@app/views/support-service/models/commonDataProtocol';
import {debounceTime, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {HumanErrorTranslationService} from '@app/shared/services/human-error-translation.service';

export interface IDelegationsOverLimitReport {
  abonentInfo: {
    id: number;
    phone: string;
  };
  serviceId: number;
  delegationsCount: number;
  delegationsLimit: number;
}

export interface IAbonentFlat {
  id: number;
  accountId: number;
  virtual: boolean;
  address: {
    country: {
      shortName: string;
      name: string;
    },
    city: string;
    street: {
      id: number;
      name: string;
      codeKladr: string;
      codeFias: string;
      universalCode: string;
    },
    house: {
      id: number;
      number: string;
      block: string;
      building: string;
      housing: string;
    },
    entrance: {
      id: number;
      number: string;
      flatStart: number;
      flatEnd: number;
      additionalFlatRanges: [
        {
          id: number;
          flatStart: number;
          flatEnd: number;
        }
      ]
    },
    flat: number;
  };
}

export interface IToAbonent {
  phone: number;
  id: number;
  resolved: boolean;
}

export interface IAddressConnections {
  flat: IAbonentFlat;
  connections: IConnection[];
}

export interface IDelegatedAbonentsConnections {
  abonent: {
    id: number;
    phone: number;
  };
  connections: {
    id: number;
  }[];
}

export interface IAbonentDelegationMobileVersion {
  addressConnections: IAddressConnections[];
  delegatedAbonentsConnections: IDelegatedAbonentsConnections[];
}

export interface IAbonentDelegation {
  connection: IConnection;
  flat: IAbonentFlat;
  toAbonent: IToAbonent;
  fromAbonent: IToAbonent;
}

export interface IAbonentConnection {
  flat: IAbonentFlat;
  connections: IConnection[]; // все подключенные услуги абонента
}

export interface IConnectionMappedDate {
  serviceLimitControl?: boolean;
  abonentLimitControl?: boolean;
  personControl?: FormControl;
  progress$?: BehaviorSubject<boolean>;
  serviceType?: string;
  fullAddress?: string;
  accountNumber?: number;
  toAbonent: IToAbonent;
  fromAbonent: IToAbonent;
  fromAbonentPhone: string;
}

export interface IServicesConnection {
  id: number;
  name: string;
  customName: string;
  type: string; // SoftwareIntercom, HardwareIntercom, HouseChat, VideoSurveillance, PersonalSurveillance, Gate
  delegationTunings: {
    limit: number;
  };
}

export interface IConnection extends IConnectionMappedDate {
  id: number; // id подключения услуги
  account: {
    id: number;
    number: number;
  };
  service: IServicesConnection;
  delegationsLeft: number; // Сколько ещё можно делегировать
  delegationTunings: {
    limit: number // Лимит делегированний, на подключении
  };
  isBlocked: boolean;

  control?: FormControl;
}

export interface ISetUpDelegations {
  fromAbonent: number;
  toAbonent: number;
  connections: {
    id: number;
  }[];
}


@Injectable({
  providedIn: 'root'
})
export abstract class ServiceApiService extends ApiService {
  protected headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  protected constructor(
    httpClient: HttpClient,
    humanErrorTranslationService: HumanErrorTranslationService,
    private translate: TranslateService
  ) {
    super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1', 'v2', 'v3', 'v4']);
  }

  abstract create(request: ServiceCreateRequest)
    : Observable<ServiceCreateResponse>;

  abstract getPage(page: number, size: number, name?: string)
    : Observable<PagedResponse<ServiceInfoResponse>>;

  update(serviceId: number, request: UpdateServiceRequest): Observable<void> {
    return this.patch<UpdateServiceRequest, void>(`/services/${serviceId}`, 1, request, {headers: this.headers});
  }

  getServices(accountNum: number, common: boolean, entranceId: number, page: number = 0, size: number = 10): Observable<PagedResponse<ServicesList>> {
    const params: any = {page, size};
    if (accountNum) {
      params['accountNum'] = accountNum;
    }
    if (common) {
      params['common'] = common;
    }
    if (entranceId) {
      params['entranceId'] = entranceId;
    }

    return this.get(`/services`, 1, {params});
  }

  getMainServiceByDependentService(dependantServiceId: number): Observable<ServiceInfoResponse> {
    return this.get(`/services/${dependantServiceId}/main_services`, 1);
  }

  getEntranceServices(entranceId: number): Observable<ServiceInfoResponse[]> {
    return this.get(`/entrances/${entranceId}/services`, 1);
  }

  getInfo(serviceId: number): Observable<ServiceInfoResponse> {
    return this.get<ServiceInfoResponse>(`/services/${serviceId}`, 1);
  }

  getService(serviceId: number): Observable<any> {
    return this.get(`/services/${serviceId}`, 1);
  }

  deleteService(serviceId: number): Observable<void> {
    return this.delete(`/services/${serviceId}`, 1);
  }

  getEntrances(serviceId: number): Observable<Address[]> {
    return this.get(`/services/${serviceId}/entrances`, 1);
  }

  getEntrancesFlats(entranceId: number): Observable<ServiceEntranceFlatResponse[]> {
    return this.get(`/entrances/${entranceId}/flats`, 1);
  }

  getCameras(serviceId: number): Observable<Camera[]> {
    return this.get(`/services/${serviceId}/cameras`, 1);
  }

  getCamerasV2(serviceId: number): Observable<ServiceCamerasV2Response[]> {
    return this.get(`/services/${serviceId}/cameras`, 2);
  }

  getAccounts(serviceId: number): Observable<Account[]> {
    return this.get<Account[]>(`/services/${serviceId}/accounts`, 1);
  }

  getConnections(serviceId: number, entranceId?: number): Observable<ServiceConnection[]> {
    const params: HttpParams = new HttpParams();

    if (entranceId) {
      params.set('entranceId', entranceId.toString());
    }

    return this.get<ServiceConnection[]>(`/services/${serviceId}/connections`, 1, {params});
  }

  createConnection(serviceId: number, request: ServiceCreateConnectionRequest): Observable<ServiceConnection> {
    return this.post(`/services/${serviceId}/connections`, 1, request, {headers: this.headers});
  }

  updateConnection(connectionId: number, accountId: number): Observable<void> {
    return this.patch(`/services_connections/${connectionId}`, 1, {accountId}, {headers: this.headers});
  }

  updateConnectionTariff(connectionId: number, tariff: Tariff): Observable<void> {
    return this.patch(`/services_connections/${connectionId}`, 1, {...tariff}, {headers: this.headers});
  }

  updateServiceTariff(serviceId: number, tariff: Tariff): Observable<void> {
    return this.patch(`/services/${serviceId}`, 1, {...tariff}, {headers: this.headers});
  }

  getKeys(serviceId: number): Observable<KeysResponse[]> {
    return this.get<KeysResponse[]>(`/services/${serviceId}/keys`, 1);
  }

  getRdas(serviceId: number): Observable<RdaResponse[]> {
    return this.get<RdaResponse[]>(`/services/${serviceId}/rdas`, 1);
  }

  getSignUps(serviceId: number): Observable<ServiceSignUpsResponse[]> {
    return this.get(`/services/${serviceId}/sign_ups`, 1);
  }

  connectEntrance(serviceId: number, entranceId: number, prefix: string): Observable<void> {
    const body: any = {prefix};
    return this.post(`/services/${serviceId}/entrances/${entranceId}`, 1, body, {headers: this.headers});
  }

  disconnectEntrance(serviceId: number, entranceId: number): Observable<void> {
    return this.delete(`/services/${serviceId}/entrances/${entranceId}`, 1);
  }

  connectCamera(serviceId: number, cameraId: number, intercomPanelId?: number, deviceId?: number): Observable<void> {
    const request: Partial<{ intercomId: number; deviceId: number }> = {};

    if (intercomPanelId) {
      request.intercomId = intercomPanelId;
    }
    if (deviceId) {
      request.deviceId = deviceId;
    }

    return this.post(`/services/${serviceId}/cameras/${cameraId}`, 1, request, {headers: this.headers});
  }

  disconnectCamera(serviceId: number, cameraId: number): Observable<void> {
    return this.delete<void>(`/services/${serviceId}/cameras/${cameraId}`, 1, null);
  }

  updateCameraConnection(serviceId: number, cameraId: number, request: Partial<ServiceCameraUpdateRequest>): Observable<void> {
    return this.patch(`/services/${serviceId}/cameras/${cameraId}`, 1, request, {headers: this.headers});
  }

  connectKey(serviceId: number, deviceId: number): Observable<void> {
    return this.post(`/services/${serviceId}/keys/${deviceId}`, 1, null, {headers: this.headers});
  }

  connectIntercom(serviceId: number, request: ServiceIntercomConnectRequest): Observable<void> {
    return this.post(`/services/${serviceId}/intercoms`, 1, request, {headers: this.headers});
  }

  connectRda(serviceId: number, rdaId: number): Observable<void> {
    return this.post<void>(`/services/${serviceId}/rdas/${rdaId}`, 1, null, {headers: this.headers});
  }

  disconnectRda(serviceId: number, rdaId: number): Observable<void> {
    return this.delete<void>(`/services/${serviceId}/rdas/${rdaId}`, 1, null);
  }

  connectIntercomPanel(serviceId: number, request: IntercomPanelConnectRequest): Observable<void> {
    return this.post<IntercomPanelConnectRequest, void>(`/services/${serviceId}/intercoms`, 1, request, {headers: this.headers});
  }

  disconnectIntercomPanel(serviceId: number, intercomPanelId: number): Observable<void> {
    return this.delete(`/services/${serviceId}/intercoms/${intercomPanelId}`, 1);
  }

  blockServiceConnection(serviceConnectionId: number): Observable<void> {
    return this.put(`/services_connections/${serviceConnectionId}/block`, 1);
  }

  unblockServiceConnection(serviceConnectionId: number): Observable<void> {
    return this.delete(`/services_connections/${serviceConnectionId}/block`, 1);
  }

  deleteServiceConnection(serviceConnectionId: number) {
    return this.delete(`/services_connections/${serviceConnectionId}`, 1);
  }

  connectDependantService(serviceId: number, dependantServiceId: number): Observable<void> {
    return this.post(`/services/${serviceId}/dependencies/${dependantServiceId}`, 1);
  }

  delegationsOverLimitReport(serviceId: number, limit: number = null): Observable<IDelegationsOverLimitReport[]> {
    let params: string = null;
    if (limit >= 0 && limit !== null) {
      params = `?limit=${limit}`;
    }
    return this.get<IDelegationsOverLimitReport[]>(
      `/services/${serviceId}/delegations_over_limit_report${params ? `${params}` : ''}`,
      1
    );
  }

  // null - неограниченный
  delegationTunings(serviceId: number, limit: number | null): Observable<void> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.patch(
      `/services/${serviceId}/delegation_tunings`,
      1,
      {
        limit
      },
      {
        headers
      }
    );
  }

  abonentConnection(abonentId: number): Observable<IAbonentConnection[]> {
    return this.get(`/abonents/${abonentId}/connections`, 1);
  }

  toAbonentDelegation(abonentId: number): Observable<IAbonentDelegation[]> {
    return this.get(`/delegations?toAbonentId=${abonentId}`, 2);
  }

  fromAbonentDelegation(abonentId: number): Observable<IAbonentDelegation[]> {
    return this.get(`/delegations?fromAbonentId=${abonentId}`, 2);
  }

  fromAbonentToAbonentDelegation(fromAbonentId: number, toAbonentId: number): Observable<IAbonentDelegation[]> {
    return this.get(`/delegations?fromAbonentId=${fromAbonentId}&toAbonentId=${toAbonentId}`, 2);
  }

  fromAbonentToAbonentDelegationMobile(fromAbonentId: number, toAbonentId: number): Observable<IAbonentDelegationMobileVersion> {
    return this.get(`/delegations?fromAbonentId=${fromAbonentId}&toAbonentId=${toAbonentId}`, 4);
  }

  setUpDelegation(delegations: ISetUpDelegations): Observable<IAbonentDelegationMobileVersion> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/delegations`, 2, {...delegations}, {headers});
  }

  deleteDelegation(fromAbonentId: number, toAbonentId: number): Observable<void> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.delete(`/delegations?fromAbonentId=${fromAbonentId}&toAbonentId=${toAbonentId}`, 2, {headers});
  }

  abonentLimitChange(connectionId: number, limit: number): Observable<void> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.patch(
      `/services_connections/${connectionId}/delegation_tunings`,
      1,
      {limit},
      {headers}
    );
  }

  getPersonalSurveillanceList<TContent>(page: number, size: number, filter?: string, sorting?: string): Observable<PersonalSurveillance.PageableResponse<TContent>> {
    return this.get(`/services/personal_surveillance?page=${page}&size=${size}${filter ? '&' + filter : ''}${sorting ? '&sort=' + sorting : ''}`, 1);
  }

  getPersonalSurveillance(id: number): Observable<PersonalSurveillance.ServiceByIdResponse> {
    return this.get(`/services/personal_surveillance/${id}`, 1);
  }

  postPersonalSurveillanceList(payload: PersonalSurveillance.PostPersonalSurveillance): Observable<PersonalSurveillance.ServiceResponse> {
    return this.post(`/services/personal_surveillance`, 1, payload, {headers: this.headers});
  }

  protected preparGetPageParams(page: number = 0, size: number = 10, filter?: string): HttpParams {
    let params = new HttpParams({
      fromObject: {
        page: page.toString(),
        size: size.toString()
      }
    });

    if (filter) {
      params = params.set('filter', filter);
    }

    return params;
  }

  protected preparePageResponse(
    response: PagedResponse<ServiceInfoResponse>
  ): PagedResponse<ServiceInfoResponse> {
    response.content.forEach((service: ServiceInfoResponse) => {
      if (service.customName === '') {
        service.customName = null;
      }
    });

    return response;
  }

  getEntity(searchText: string, additionOption?: { problem: string }): Observable<ICommonDataProtocol[]> {
    return this.getPage(0, 10, searchText).pipe(debounceTime(1000), map(response => response.content.map(result => {
      const dataForNextStep = result[this.fieldType(additionOption.problem)?.requestField];
      return {
        name: result.name,
        value: result.id,
        customName: result.customName,
        dataForNextStep: dataForNextStep?.length ? dataForNextStep.map(entity => {
          return {
            name: entity[this.fieldType(additionOption?.problem).nameField],
            value: entity[this.fieldType(additionOption?.problem).valueField],
            tableColumneName: this.fieldType(additionOption.problem).nameField
          };
        }) : []
      };
    })));
  }

  protected fieldType(problem: string): IQueryQualifier {
    switch (problem) {
      case this.translate.instant('shared.entities.integrations.services.field_type.camera'):
        return {
          requestField: 'cameras',
          nameField: 'id',
          valueField: 'id'
        };
      case this.translate.instant('shared.entities.integrations.services.field_type.rda'):
        return {
          requestField: 'rdas',
          nameField: 'uid',
          valueField: 'uid'
        };
      case this.translate.instant('shared.entities.integrations.services.field_type.intercom'):
        return {
          requestField: 'rdas',
          nameField: 'uid',
          valueField: 'uid'
        };
      case this.translate.instant('shared.entities.integrations.services.field_type.call'):
        return {
          requestField: 'rdas',
          nameField: 'uid',
          valueField: 'uid'
        };
    }
  }
}
