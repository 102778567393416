<ng-container
  *ngIf="tableData && !loading; then content; else loader"></ng-container>

<ng-template #loader>
  <div class="loader">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<ng-template #content>
  <div
    class="content-wrapper">
    <div class="content-wrapper-table" [ngClass]="{'hid-table-border' : hidBorder}">
      <p-table
        [value]="tableData | tableFilter: { currentPage: paginatorConfig.page, pageSize: paginatorConfig.pageSize, showSkeleton: showSkeleton }"
        [(selection)]="selectedTableData"
        (selectionChange)="onSelectionChange($event)"
        [scrollHeight]="scrollTableHeight"
        [resizableColumns]="resizableColumns"
        [scrollable]="scrollable"
        [styleClass]="styleClass"
        [dataKey]="primeTableConfig.key"
        (sortFunction)="onSort($event)"
        [customSort]="true"
        [loading]="showSkeleton">
        <ng-template pTemplate="caption">
          <ng-content select="[addition-actions]"></ng-content>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th
              *ngFor="let config of primeTableConfig.header"
              class="content-wrapper-table__header"
              [class]="config.className"
              [pSortableColumn]="config.sort ? config.field : null">
              <!--Sorting section-->
              <span class="content-wrapper-table__header-text">
                {{ config.name }}
              </span>
              <p-sortIcon
                *ngIf="config.sort"
                [field]="config.field"></p-sortIcon>
              <!-- ************* -->
            </th>
            <!--Action header section-->
            <ng-container *ngIf="primeTableConfig.showActionColumn">
              <th style="width: 60px"></th>
            </ng-container>
            <!-- ************* -->
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr *ngFor="let row of mockRows">
            <td *ngFor="let column of primeTableConfig.header">
              <p-skeleton class="content-wrapper-table__skeleton"></p-skeleton>
            </td>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-data>
          <tr class="content-wrapper-table__body" aria-colspan="5">
            <td
              *ngFor="let config of primeTableConfig.body"
              [class]="config.className">
              <!--Link and navigation section-->
              <a
                @appearDisappear
                class="content-wrapper-table__body__link"
                *ngIf="config.isLink"
                (click)="onNavigate(data)">
                {{ data[config.field] }}
              </a>
              <!-- ************************** -->

              <!-- Services status -->
                <ng-container *ngIf="config.isServiceStatus">
                  <div [ngClass]="{'active': !data[config.field], 'blocked': data[config.field] }"></div>
                </ng-container>
              <!-- ****************** -->

              <!-- Tube mode -->
                <ng-container *ngIf="config.isTube">
                  <img *ngIf="data[config.field]" src="assets/icons/ic-tube-dark.svg" alt="error">
                </ng-container>
              <!-- ****************** -->

              <!--Status section-->
              <app-company-user-status
                *ngIf="config.isStatus"
                [blocked]="data[config.field]"
              ></app-company-user-status>

              <app-request-status
                *ngIf="config.isSupportStatus"
                [tickets]="data"
              ></app-request-status>
              <!-- ************************** -->

              <!--Native status-->
              <span
                *ngIf="config.isNativeRole"
                [pTooltip]="(data[config.field] | nativeRole).toString()"
                class="{{(data[config.field] | nativeRole).length ? '' : 'content-wrapper-table__body-empty'}}"
              >
                {{
                  (data[config.field] | nativeRole).length > 2 ?
                    (data[config.field] | nativeRole).slice(0, 2) + '...' :  (data[config.field] | nativeRole).length ?
                    (data[config.field] | nativeRole) : ('shared.prime_base_table.not_assigned' | translate)
                }}
              </span>
              <!-- ************************** -->

              <!--Base data section-->
              <div
              class="content-wrapper-table__body-base"
              *ngIf="
              !config.isLink &&
              !config.isStatus &&
              !config.isNativeRole &&
              !config.isSupportStatus &&
              !config.isServiceStatus &&
              !config.isTube">
                <span
                  @appearDisappear
                  class="content-wrapper-table__body__text">
                  {{ data[config.field] }}
                </span>
                <div
                  *ngIf="config.isBaseLink"
                  class="content-wrapper-table__body-base-link"
                  (click)="navigate.emit({ data, field: config.field })"
                ><mat-icon [style]="'height: 25px; width: 18px;'" svgIcon="right-arrow"></mat-icon></div>
              </div>
              <!-- *************** -->
            </td>
            <!--Action section (edit, delete...)-->
            <th
              @appearDisappear
              *ngIf="primeTableConfig.showActionColumn"
              style="width: 60px">
              <div class="content-wrapper-table__body-action">
                <mat-icon
                  *ngIf="!(primeTableConfig.rolesAccessMode | rolesAccess: data)"
                  [matTooltip]="'shared.prime_base_table.tooltip.no_edit_access' | translate"
                  svgIcon="info">
                </mat-icon>
                <i
                  *ngIf="(primeTableConfig.rolesAccessMode | rolesAccess: data)"
                  class="pi pi-pencil"
                  (click)="onEdit(data)"></i>
                <i
                  *ngIf="(primeTableConfig.rolesAccessMode | rolesAccess: data)"
                  class="pi pi-trash"
                  (click)="onDelete(data)"></i>
              </div>
            </th>
            <!-- *************** -->
            <!-- Custom action -->
            <th @appearDisappear *ngIf="primeTableConfig.showCustomActionColumn">
              <p-overlayPanel #op>
                <ng-template pTemplate="content">
                  <ng-container *ngFor="let item of menuItems">
                    <div
                      *ngIf="item.name(data) as name"
                      (click)="onMenuItemSelect(item, op, data)"
                      [style]="item.style"
                      class="content-wrapper-table__body-custom"
                    ><span>{{name}}</span>
                    </div>
                  </ng-container>
                </ng-template>
              </p-overlayPanel>
              <i class="pi pi-ellipsis-v" style="color: #0DA7E2; width: 30px" (click)="op.toggle($event)"></i>
            </th>
            <!-- *************** -->
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr class="content-wrapper-table__empty" @appearDisappearFast>
              <td colspan="20">
                <div class="content-wrapper-table__empty-container">
                  <span class="content-wrapper-table__empty-container__header">{{'shared.prime_base_table.message.empty' | translate }}</span>
                  <img *ngIf="showEmptyImg" class="content-wrapper-table__empty-container__img" src="assets/icons/ic-not-found.svg" alt="error">
                  <span *ngIf="showEmptyImg" class="content-wrapper-table__empty-container__suggestion">
                    {{'shared.prime_base_table.message.suggestion' | translate }}
                  </span>
                </div>
              </td>
          </tr>
        </ng-template>
      </p-table>
      <div @appearDisappear class="content-wrapper-table-pagination" *ngIf="paginatorConfig !== null">
        <app-custom-paginator
          *ngIf="!showSkeleton"
          [paginatorConfig]="paginatorConfig"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onPageSizeChange($event)"
        ></app-custom-paginator>
      </div>
    </div>
  </div>
</ng-template>
