import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AsyncPipe, CommonModule, NgClass, NgIf} from '@angular/common';
import {
  DelegationComponent,
  DelegationConfig
} from '@app/views/services/submodules/shared/delegation/delegation.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {ChipModule} from 'primeng/chip';
import {TranslateModule} from '@ngx-translate/core';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextModule} from 'primeng/inputtext';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TableModule} from 'primeng/table';
import {ComponentLoaderModule} from '@app/shared/ui';
import {ButtonModule} from 'primeng/button';
import {catchError, switchMap, takeUntil} from 'rxjs/operators';
import {IAbonentConnection, IConnection} from '@app/shared/entities/rd';
import {
  AbonentDelegationEntity,
  ServiceConverterPipe
} from '@app/views/services/submodules/shared/abonents-delegation/pipes/service-converter.pipe';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, of} from 'rxjs';

@Component({
  selector: 'app-abonents-delegation',
  standalone: true,
  imports: [
    TabMenuModule,
    ChipModule,
    NgClass,
    TranslateModule,
    CheckboxModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    OverlayPanelModule,
    TableModule,
    ComponentLoaderModule,
    AsyncPipe,
    ButtonModule,
    CommonModule,
    ServiceConverterPipe
  ],
  templateUrl: './abonents-delegation.component.html',
  styleUrls: [
    './abonents-delegation.component.scss',
    '../delegation/delegation.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AbonentsDelegationComponent extends DelegationComponent implements OnInit, OnDestroy {
  public abonentConnection: IConnection[] = [];

  protected abonentId: number = null;
  protected readonly AbonentDelegationEntity = AbonentDelegationEntity;

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap((res: { id: number }) => {
          this.abonentId = res.id;
          return this.service.abonentConnection(res.id);
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((response: IAbonentConnection[]) => {
        response.forEach((res) => {
          this.abonentConnection.push(
            ...res.connections.map((c) => {
              return {
                ...c,
                serviceLimitControl: c.service.delegationTunings.limit === c.delegationTunings.limit,
                abonentLimitControl: c.service.delegationTunings.limit !== c.delegationTunings.limit,
                personControl: new FormControl(
                  c.delegationTunings?.limit ? c.delegationTunings.limit : 0, []
                ),
                progress$: new BehaviorSubject(false),
              };
            })
          );
        });
        this.loading$.next(false);
      });
  }

  public getServiceLimitText(limit: number | null): string {
    if (limit === 0) {
      return this.translate.instant(
        'service-navbar.tab.action.addition.service_cant_delegation_message'
      );
    }
    if (limit) {
      return this.translate.instant(
        'service-navbar.tab.action.addition.service_limit_message',
        {
          text: limit
        }
      );
    }
    if (limit === null) {
      return this.translate.instant(
        'service-navbar.tab.action.addition.service_unlimited_message'
      );
    }
    return '';
  }

  public getLabel(connection: IConnection): string {
    if (connection.abonentLimitControl) {
      if (connection.personControl.value === null) {
        return this.translate.instant('service-navbar.tab.action.addition.unrestricted_text');
      }
      if (connection.personControl.value === 0) {
        return this.translate.instant('service-navbar.tab.action.addition.cant_delegation');
      }
      if (connection.delegationTunings.limit > 0) {
        return `${this.translate.instant('service-navbar.tab.action.addition.contractor_text')} ${connection.personControl.value}`;
      }
      return `${this.translate.instant('service-navbar.tab.action.addition.contractor_text')} ${connection.personControl.value}`;
    }
    if (connection.serviceLimitControl) {
      if (connection.service.delegationTunings.limit === null) {
        return this.translate.instant('service-navbar.tab.action.addition.unrestricted_text');
      }
      if (connection.service.delegationTunings.limit === 0) {
        return this.translate.instant('service-navbar.tab.action.addition.cant_delegation');
      }
      if (connection.service.delegationTunings.limit > 0) {
        return `${this.translate.instant('service-navbar.tab.action.addition.contractor_text')} ${connection.service.delegationTunings.limit}`;
      }
    }
  }

  public onModeChange(config: DelegationConfig, connection: IConnection): void {
    if (config === DelegationConfig.Service) {
      connection.serviceLimitControl = true;
      connection.abonentLimitControl = false;
    }
    if (config === DelegationConfig.Limited) {
      connection.serviceLimitControl = false;
      connection.abonentLimitControl = true;
    }
  }

  public onServiceNavigate(id: number): void {
    this.router.navigate([`/services/software-intercoms/${id}`]);
  }

  public onAbonentDelegationChange(connection: IConnection): void {
    connection.progress$.next(true);
    let limit = null;
    if (connection.serviceLimitControl) {
      limit = connection.service.delegationTunings.limit;
    }
    if (connection.abonentLimitControl) {
      limit = +connection.personControl.value;
    }
    this.service.abonentLimitChange(connection.id, limit)
      .pipe(
        catchError((error: HttpErrorResponse) => of(error))
      )
      .subscribe((response) => {
        connection.progress$.next(false);
        if (response instanceof HttpErrorResponse) {
          this.snackbarService.showMessage(
            this.translate.instant(
              this.translate.instant(
                'service-navbar.tab.action.addition.limit_update_error'
              ),
              {
                text: response.message
              }
            ),
            'error'
          );
          return;
        }

        this.snackbarService.showMessage(
          this.translate.instant(
            'service-navbar.tab.action.addition.limit_update_success'
          ),
          'success'
        );
        this.getAbonentConnections();
      });
  }

  public onPersonControlCheck(personControl: FormControl): void {
    if (+personControl.value < 0) {
      personControl.setValue(0);
    }
  }

  private getAbonentConnections(): void {
    this.service.abonentConnection(this.abonentId).subscribe((response: IAbonentConnection[]) => {
      this.abonentConnection = [];
      response.forEach((connection) => {
        this.abonentConnection.push(
          ...connection.connections.map((c) => {
            return {
              ...c,
              serviceLimitControl: c.service.delegationTunings.limit === c.delegationTunings.limit,
              abonentLimitControl: c.service.delegationTunings.limit !== c.delegationTunings.limit,
              personControl: new FormControl(
                c.delegationTunings?.limit ? c.delegationTunings.limit : 0, []
              ),
              progress$: new BehaviorSubject(false),
            };
          })
        );
      });
      this.cd.detectChanges();
    });
  }
}
