<app-sign-ups-warnings
  *ngIf="showWarning$ | async"
  [waitAbonentSignUpsCount]="waitAbonentSignUpsCount$ | async"
  (confirmationOwners)="onConfirmationOwners()"></app-sign-ups-warnings>

<div
  class="sing-up"
  *ngIf="isMobileMode">
  <button
    *ngIf="showScrollTopButton"
    pButton
    class="p-ripple scroll-top-button"
    (click)="scrollToTop()"
    icon="pi pi-angle-up"></button>
  <div class="sing-up-first">
    <p-dropdown
      optionLabel="text"
      [options]="links$ | async"
      [(ngModel)]="selectedNavbar"
      (ngModelChange)="onLinksChange($event)">
      <ng-template
        let-link
        pTemplate="item">
        <span>
          {{ getText(link.text) | translate }}
        </span>
      </ng-template>
      <ng-template pTemplate="selectedItem">
        <span>
          {{ getText(selectedNavbar.text) | translate }}
        </span>
      </ng-template>
    </p-dropdown>
    <div class="sing-up-first-report">
      <i class="pi pi-download"></i>
    </div>
  </div>
  <div class="sing-up-second">
    <span class="p-input-icon-left">
      <i
        [class]="
          (typing$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-search'
        "></i>
      <input
        pInputText
        [placeholder]="'shared.ui.paginated_page_wrapper.search' | translate"
        (input)="onTyping($event)" />
    </span>
    <p-dropdown
      [options]="requestType"
      [optionLabel]="'name'"
      [optionValue]="'value'"
      (onChange)="onRequestTypeChange($event.value)"
      class="sing-up-second__dropdown"></p-dropdown>
  </div>
  <div class="sing-up-cards">
    <div
      class="sing-up-cards-card"
      *ngFor="let signUp of signUpsResponse | dataSourceConverter">
      <div class="card__header">
        <div class="card__header-group">
          <span class="card__icon card__icon--red">
            <ng-container *ngIf="signUp.signUpType === 'DISCONNECTION'">
              <img
                src="assets/icons/ic-disconection.svg"
                style="margin-top: 4px" />
            </ng-container>
            <ng-container *ngIf="signUp.signUpType === 'CONNECTION'">
              <img src="assets/icons/ic-connections.svg" />
            </ng-container>
          </span>
          <span class="card__phone">{{ signUp.phone }}</span>
          <span class="card__icon card__icon--blue">
            <mat-icon
              [style]="'height: 25px; width: 18px;'"
              svgIcon="right-arrow"
              (click)="onArrowClick(signUp)"
            ></mat-icon>
          </span>
        </div>
        <div class="card__header-group">
          <span class="card__date">{{ signUp.createdAt }}</span>
          <span class="card__icon card__icon--menu">
            <p-overlayPanel #op>
              <ng-template pTemplate="content">
                <ng-container *ngFor="let item of menuItems">
                  <div
                    *ngIf="item.name(signUp) as name"
                    (click)="onMenuItemSelect(item, op, signUp)"
                    [style]="item.style"
                    class="content-wrapper-table__body-custom">
                    <span>{{ name }}</span>
                  </div>
                </ng-container>
              </ng-template>
            </p-overlayPanel>
            <button
              mat-icon-button
              aria-label=""
              (click)="op.toggle($event)">
              <mat-icon svgIcon="submenu"></mat-icon>
            </button>
          </span>
        </div>
      </div>
      <div class="card__body">
        <div class="card__address">
          <span class="card__city">{{ signUp.address | address }}</span>
          <span class="card__address-icon">
            <img
              [src]="'/assets/icons/ic-tube-dark.svg'"
              alt="'error'" />
          </span>
        </div>
        <div class="card__status">
          <div
            class="card__status-icon"
            [ngClass]="{
              'request-success': signUp.isFreeAddress === true,
              'request-warn': signUp.isFreeAddress === false
            }"></div>
          <span class="card__apartment">кв. {{ signUp.flatNumber }}</span>
        </div>
        <div
          class="card__services"
          *ngIf="signUp.services?.length">
          <div class="card__service-group">
            <ul class="card__service-list">
              <li
                class="card__service-item"
                *ngFor="let service of signUp.services">
                {{
                  "service-navbar.tab.action.addition." + ServiceType[service]
                    | translate
                }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="card__ads"
          *ngIf="signUp?.adSource || signUp?.adCampaign">
          <div
            class="card__ad"
            *ngIf="signUp?.adCampaign">
            <span class="card__ad-header">Реклама (компания)</span>
            <span class="card__ad-content">{{ signUp.adCampaign }}</span>
          </div>
          <div
            class="card__ad"
            *ngIf="signUp?.adSource">
            <span class="card__ad-header">Реклама (источник)</span>
            <span class="card__ad-content">{{ signUp.adSource }}</span>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="waitTableData$ | async">
      <div class="sing-up-cards-card sing-up-cards-card-fake">
        <p-skeleton
          height="2rem"
          styleClass="mb-2" />
        <p-skeleton
          height="2rem"
          styleClass="mb-2" />
        <p-skeleton
          height="2rem"
          styleClass="mb-2" />
        <p-skeleton
          height="2rem"
          styleClass="mb-2" />
        <p-skeleton
          height="2rem"
          styleClass="mb-2" />
      </div>
    </ng-container>
  </div>
</div>

<div
  class="sing-up"
  *ngIf="!isMobileMode">
  <div class="sing-up-header">
    <span class="p-input-icon-left">
      <i
        [class]="
          (typing$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-search'
        "></i>
      <input
        pInputText
        [placeholder]="'shared.ui.paginated_page_wrapper.search' | translate"
        (input)="onTyping($event)" />
    </span>
    <p-dropdown
      [options]="requestType"
      [optionLabel]="'name'"
      [optionValue]="'value'"
      (onChange)="onRequestTypeChange($event.value)"></p-dropdown>
    <p-button
      styleClass="p-button-info p-button-text"
      [label]="'sign_ups.page.button.report' | translate"
      [icon]="'pi pi-download'"
      (click)="onReport()"
      iconPos="left"></p-button>
  </div>
  <div class="sing-up-navbar">
    <p-selectButton
      [disabled]="waitTableData$ | async"
      [options]="links$ | async"
      [(ngModel)]="selectedNavbar"
      (ngModelChange)="onLinksChange($event)"
      optionLabel="name"
      [multiple]="false">
      <ng-template let-item>
        <span>
          {{ getText(item.text) | translate }}
        </span>
      </ng-template>
    </p-selectButton>
  </div>
  <div class="sing-up-table">
    <app-table-with-server-pagination
      [tableData]="signUpsResponse | dataSourceConverter"
      [primeTableConfig]="singUpTableConfig | async"
      [paginatorConfig]="paginatorConfig"
      [menuItems]="menuItems"
      [loading]="waitTableData$ | async"
      (pageChange)="onPageChange($event)"
      (arrowClick)="onArrowClick($event)"
    ></app-table-with-server-pagination>
  </div>
</div>
