import { Injectable, OnDestroy } from '@angular/core';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { RdvaPlaylistItem, VideoshotGetRequest } from '@app/shared/entities/integrations';
import { VideoPlayerVideoshotPopupState } from '../models';
import { VideoPlayerClearState, VideoPlayerCreateVideoshotTask, VideoPlayerGetPlaylists, VideoPlayerGetPlaylistsSuccess, VideoPlayerGetVideoshot, VideoPlayerInitVideoshotState, VideoPlayerSelectCurrentSource } from './video-player.actions';
import { videoPlayerSelectors } from './video-player.selectors';

@Injectable()
export class VideoPlayerFacade implements OnDestroy {
    constructor(
        private store: Store<State>
    ) { }

    videoshotState$: Observable<VideoPlayerVideoshotPopupState> = this.store.select(videoPlayerSelectors.selectVideoshotState);
    private onDestroy$: Subject<void> = new Subject();

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getPlaylists(rdvaUri: string, cameraId: number, timeZone?: number) {
        this.store.dispatch(new VideoPlayerGetPlaylists(rdvaUri, cameraId, timeZone));
    }

    getPlaylistsSuccess(playlists: RdvaPlaylistItem[]) {
        this.store.dispatch(new VideoPlayerGetPlaylistsSuccess(playlists));
    }

    createVideoshotTask(request: VideoshotGetRequest) {
        this.store.dispatch(new VideoPlayerCreateVideoshotTask(request));
    }

    getVideoshot(request: VideoshotGetRequest) {
        this.store.dispatch(new VideoPlayerGetVideoshot(request));
    }

    initVideoshotState() {
        this.store.dispatch(new VideoPlayerInitVideoshotState());
    }

    selectCurrentSource(source: string) {
        this.store.dispatch(new VideoPlayerSelectCurrentSource(source));
    }

    clearState() {
        this.store.dispatch(new VideoPlayerClearState());
    }

    async addSelectCurrentSourceListener(
        handleResponse: (source: string) => void
    ) {
        await this.store.select(videoPlayerSelectors.selectCurrentSource)
            .pipe(
                takeUntil(this.onDestroy$),
                map((source: string) => handleResponse(source))
            )
            .toPromise();
    }

    async addPlaylistsLoadedListener(
        handleResponse: (playlists: RdvaPlaylistItem[]) => void
    ) {
        await this.store.select(videoPlayerSelectors.selectPlaylists)
            .pipe(
                takeUntil(this.onDestroy$),
                map((playlists: RdvaPlaylistItem[]) => handleResponse(playlists))
            )
            .toPromise();

    }
}
