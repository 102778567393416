import { HttpErrorResponse } from '@angular/common/http';
import { RdvaPlaylistItem } from '@app/shared/entities/integrations';
import * as root from '@app/store/reducers';
import { VideoPlayerVideoshotPopupState } from '../models';

export interface VideoPlayerState {
    currentSource: string;
    playlists: RdvaPlaylistItem[];
    videoshotPopupState: VideoPlayerVideoshotPopupState;
    videoshotError: HttpErrorResponse;
}

export const videoPlayerInitialState: VideoPlayerState = {
    currentSource: undefined,
    playlists: undefined,
    videoshotPopupState: 'create',
    videoshotError: undefined
};

export interface VideoPlayerModuleState {
    videoPlayer: VideoPlayerState;
}

export interface VideoPlayerModuleRootState extends root.State {
    'video-player': VideoPlayerModuleState;
}
