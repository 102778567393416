import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreFacade } from '@app/core/store';
import { MaterialModule } from '@app/material.module';
import { CameraInfoPopupModule, SnackbarModule } from '@app/shared/components';
import { LazyLoadImageHooks } from '@app/shared/entities/common/lazy-load-image';
import {
  BottomSheetComponent,
  EditPopupModule,
  MatDatepickerCustomComponent,
  MatPaginatorCustomComponent
} from '@app/shared/templates';
import { ButtonLinkModule, DialogWrapperModule } from '@app/shared/ui';
import { DashboardFacade } from '@app/views/dashboard/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RemovePopupComponent } from './components';
import { CameraThumbnailModule, EmptyDataComponent } from './containers';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import {
  AddressEffects,
  CamerasEffects,
  IntercomEffects
} from './store/effects/index';
import { reducers } from './store/reducers/reducers';
import { ButtonWithSpinnerLoaderModule } from './ui/button-with-spinner-loader/button-with-spinner-loader.module';
import { DocumentUploadComponent } from './components/upload/document-upload/document-upload.component';
import { ServiceCameraAlreadyExistsPopupComponent } from '@app/shared/components/camera/camera-already-exists/service-camera-already-exists-popup/service-camera-already-exists-popup.component';
import { CameraAlreadyExistsModule } from '@app/shared/components/camera/camera-already-exists/camera-already-exists.module';
import { SnowComponent } from '@app/shared/components/snow/snow.component';
import { SnowflakesToArrPipe } from '@app/shared/components/snow/snowflakes-to-arr.pipe';
import {
  CompanyUserStatusComponent
} from '@app/views/company/pages/company-roles-page/company-user-status/company-user-status.component';
import { NativeRolePipe } from './components/prime-base-table/pipes/native-role.pipe';
import { OrderListModule } from 'primeng/orderlist';
import { FileTypeDetectorPipe } from './components/upload/document-upload/file-type-detector.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FileCardComponent } from './components/upload/document-upload/file-card/file-card.component';
import { TrimLongFieldPipe } from './components/prime-base-table/pipes/trim-base-field.pipe';

const components = [
  EmptyDataComponent,
  BottomSheetComponent,
  DocumentUploadComponent,
  SnowComponent,
  SnowflakesToArrPipe,
  CompanyUserStatusComponent
];

const modalForms = [
  RemovePopupComponent,
  ServiceCameraAlreadyExistsPopupComponent
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FileSaverModule,
    NgxSkeletonLoaderModule,
    SnackbarModule,
    LazyLoadImageModule,
    HammerModule,
    NgxMatDatetimePickerModule,
    DialogWrapperModule,
    ButtonWithSpinnerLoaderModule,
    CameraInfoPopupModule,
    SharedPipesModule,
    ButtonWithSpinnerLoaderModule,
    EditPopupModule,
    StoreModule.forFeature('shared', reducers),
    EffectsModule.forFeature([IntercomEffects, CamerasEffects, AddressEffects]),
    CameraThumbnailModule,
    ButtonLinkModule,
    CameraAlreadyExistsModule,
    OrderListModule,
    TranslateModule
  ],
  declarations: [...components, ...modalForms, NativeRolePipe, FileTypeDetectorPipe, FileCardComponent, TrimLongFieldPipe],
  providers: [
    CoreFacade,
    DashboardFacade,
    { provide: MatPaginatorIntl, useClass: MatPaginatorCustomComponent },
    { provide: MatDatepickerIntl, useClass: MatDatepickerCustomComponent },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SnackbarModule,
    LazyLoadImageModule,
    NgxMatDatetimePickerModule,
    DialogWrapperModule,
    ButtonWithSpinnerLoaderModule,
    EditPopupModule,
    ...components,
    ...modalForms,
    NativeRolePipe,
    FileTypeDetectorPipe,
    FileCardComponent,
    TrimLongFieldPipe
  ]
})
export class SharedModule {}
