<app-component-loader
  *ngIf="(loading$ | async)"
  [sizePx]="50"
  [background]="true"
></app-component-loader>

<ng-container *ngIf="!(loading$ | async)">
  <div
    class="d-access"
    *ngFor="let connection of abonentConnection">
    <div class="d-access-service">
      <span>{{  connection.service?.type | serviceConverter: AbonentDelegationEntity.ServiceType }}</span>
    </div>
    <div>
      <span>
        {{
          connection.service?.customName
            ? connection.service?.customName
            : connection.service?.name
        }}
      </span>
    </div>
    <div class="d-access-state">
      <p-chip class="d-limited" [ngClass]="{
        'd-forbidden':
        (+connection.personControl?.value === 0 && connection.abonentLimitControl) ||
        ( connection.serviceLimitControl && connection.service?.delegationTunings?.limit === 0)
      }" [label]="getLabel(connection)"></p-chip>
      <p-chip
        class="d-shared"
        *ngIf="connection.delegationsLeft < 0"
        [label]="'Доступов выдано: ' + (connection.delegationTunings.limit + -connection.delegationsLeft)"
      ></p-chip>
    </div>
    <div class="d-access-header">
      <span class="d-access-header__text">
        {{
          "service-navbar.tab.action.addition.service_custom_header" | translate
        }}
      </span>
    </div>
    <div class="d-access-controls">
      <div class="d-access-controls-control">
        <p-checkbox
          [binary]="true"
          [(ngModel)]="connection.serviceLimitControl"
          (ngModelChange)="onModeChange(DelegationConfig.Service, connection)"
        ></p-checkbox>
        <span class="d-access-controls-control__text">
          {{ getServiceLimitText(connection.service.delegationTunings.limit) }}
        </span>
        <span class="d-access-controls-control__link" (click)="onServiceNavigate(connection.service.id)">
          {{ "service-navbar.tab.action.addition.move_to_service" | translate }}
        </span>
      </div>
      <div class="d-access-controls-control">
        <p-checkbox
          [binary]="true"
          [(ngModel)]="connection.abonentLimitControl"
          (ngModelChange)="onModeChange(DelegationConfig.Limited, connection)"
        ></p-checkbox>
        <span>
          {{ "service-navbar.tab.action.addition.contractor_limit_text" | translate }}
        </span>
        <input
          pInputText
          type="number"
          [formControl]="connection.personControl"
          (input)="onPersonControlCheck(connection.personControl)"
        />
      </div>
    </div>
    <div class="d-access-actions">
      <i *ngIf="connection.progress$ | async" class="pi pi-spin pi-spinner" style="font-size: 2rem; margin-right: 45px; margin-bottom: 12px"></i>
      <button
        *ngIf="!(connection.progress$ | async)"
        pButton
        [label]="'service-navbar.tab.action.addition.apply' | translate"
        (click)="onAbonentDelegationChange(connection)"
      ></button>
    </div>
  </div>
</ng-container>
