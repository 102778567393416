import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { RdeaDate } from '@app/shared/entities/common';
import { VideoshotGetRequest } from '@app/shared/entities/integrations';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui/dialog-wrapper';
import { VideoPlayerVideoshotRequest } from '../models';
import { VideoPlayerFacade } from '../store';
import { VideoPlayerVideoshotPopupState } from './../models';

@Component({
  selector: 'app-video-player-videoshot-popup.component',
  templateUrl: './video-player-videoshot-popup.component.html',
  styleUrls: ['./video-player-videoshot-popup.component.scss'],
  providers: [VideoPlayerFacade],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerVideoshotPopupComponent {
  state$: Observable<VideoPlayerVideoshotPopupState> = this.videoPlayerFacade.videoshotState$;
  private durationInSec: number;
  private dateFormatString: string;
  private videoshotRequest: VideoshotGetRequest;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<VideoPlayerVideoshotRequest, null>,
    public dialogWrapperService: DialogWrapperService,
    private videoPlayerFacade: VideoPlayerFacade
  ) { }

  onCreateVideoshotTask(dateFrom: Date, duration: number) {
    this.dateFormatString = this.prepareDateFormatString(dateFrom, duration);
    this.durationInSec = duration;

    const request: VideoshotGetRequest = {
      rdvaUri: this.data.body.rdvaUri,
      cameraId: this.data.body.cameraId,
      dateFormatString: this.dateFormatString,
      duration: this.durationInSec
    };

    this.videoPlayerFacade.createVideoshotTask(request);
  }

  onDownloadVideoshot() {
    this.videoshotRequest = {
      rdvaUri: this.data.body.rdvaUri,
      cameraId: this.data.body.cameraId,
      dateFormatString: this.dateFormatString,
      duration: this.durationInSec
    };

    this.videoPlayerFacade.getVideoshot(this.videoshotRequest);
  }

  onInitVideoshotBuilding() {
    this.videoPlayerFacade.initVideoshotState();
    this.dateFormatString = null;
    this.durationInSec = null;
  }

  /**
   * Calculate and return middle date as string
   * @param dateFrom  start date covered
   * @param duration videoshot duration in seconds
   * @returns date string in {year}-{month}-{day}-{hour}-{minute}-{second} format
   */
  private prepareDateFormatString(dateFrom: Date, duration: number): string {
    const middleDate = new Date(dateFrom.getTime() + (duration * 1000) / 2);
    const middleDateWithTimezone = new RdeaDate(middleDate.getTime() + middleDate.getTimezoneOffset() * 60 * 1000);
    return middleDateWithTimezone.getDateTimeString({ dateDivider: '-', timeDivider: '-', partsDivider: '-' });
  }
}
