import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {LocationsService, ResolutionBreakpoint, ResolutionService} from '@app/shared/services';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {SkeletonModule} from 'primeng/skeleton';
import {catchError, delay, map, mergeMap, takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {LocationResponse} from '@app/shared/models';
import {CameraApiService, PersonalCameraConfig, ServiceApiService} from '@app/shared/entities/rd';
import {SnackbarService} from '@app/shared/components';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {parseError} from '@app/shared/helpers';

export namespace ApartmentVideoCameraConnect {
  export const HowToConnect = 'https://wiki.rosdomofon.com/Поддержка/Техникам_и_администраторам/Камеры/Требования_и_советы_по_настройкам_и_проверке_видеокамер';
  export const LinkPrefix = 'rtsp://';

  export interface Output {
    formData: {
      subscriber: string;
      serviceName: string;
      selectedAccount?: number;
      createNewAccount?: boolean;
      newAccountName?: string;
    };
  }

  export interface Form {
    uri: AbstractControl<string>;
    login: AbstractControl<string>;
    password?: AbstractControl<string>;
    recordAndBroadcast?: AbstractControl<boolean>;
  }
}


@Component({
  standalone: true,
  selector: 'app-apartment-video-camera-connect',
  templateUrl: './apartment-video-camera-connect.component.html',
  styleUrls: ['./apartment-video-camera-connect.component.scss'],
  imports: [
    CommonModule,
    ButtonModule,
    DropdownModule,
    InputSwitchModule,
    InputTextModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SkeletonModule,
    TranslateModule
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ApartmentVideoCameraConnectComponent implements OnInit, OnDestroy {
  public loaded = false;
  public showLoading = false;
  public addCameraForm: FormGroup<ApartmentVideoCameraConnect.Form>;

  public get isFormValid(): boolean {
    return this.addCameraForm?.valid;
  }

  public get serviceId(): number {
    return this.dynamicDialogConfig?.data?.serviceId;
  }

  public get cameraInfo(): PersonalSurveillance.CameraFull {
    return this.dynamicDialogConfig?.data?.cameraInfo;
  }

  public get isEdit(): boolean {
    return !!this.dynamicDialogConfig?.data?.cameraInfo;
  }

  public locations: LocationResponse[];
  private destroy = new Subject<void>();

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private resolutionService: ResolutionService,
    private locationsService: LocationsService,
    private cameraApiService: CameraApiService,
    private snackbar: SnackbarService,
    private serviceApiService: ServiceApiService,
    private translateService: TranslateService,
  ) {
    this.dynamicDialogConfig.styleClass = 'apartment-video-camera-connect__modal';
    if (this.resolutionService.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)) {
      this.dynamicDialogConfig.height = '100%';
      this.dynamicDialogConfig.width = '100%';
      this.dynamicDialogConfig.styleClass = `${this.dynamicDialogConfig.styleClass} apartment-video-camera-connect__modal--full-screen`;
    }
  }

  public ngOnInit(): void {
    this.locationsService.getLocations('keys')
      .pipe(takeUntil(this.destroy), delay(1000))
      .subscribe((response) => {
        this.locations = response;
        this.addCameraForm = new FormGroup<ApartmentVideoCameraConnect.Form>({
          uri: new FormControl<string>(this.isEdit ? this.cameraInfo?.uri : ApartmentVideoCameraConnect.LinkPrefix, [Validators.required, this.simplePhoneValidator()]),
          login: new FormControl<string>(this.isEdit ? this.cameraInfo?.user : ''),
          password: new FormControl<string>(this.isEdit ? this.cameraInfo?.password : ''),
          recordAndBroadcast: new FormControl<boolean>(this.isEdit ? this.cameraInfo?.audio : true),
        });
        this.loaded = true;
      });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onSaveAction(): void {
    this.showLoading = true;
    if (this.isFormValid) {
      const formData = this.addCameraForm.getRawValue();
      const payload: PersonalCameraConfig = {
        user: formData.login || null,
        password: formData.password || null,
        uri: formData.uri,
        isPrivate: true,
        personal: true,
        audio: formData.recordAndBroadcast,
      };
      (
        this.isEdit ?
          this.cameraApiService.updateCamera2(this.cameraInfo?.id, payload) :
          this.cameraApiService.addCamera2(payload)
      )
        .pipe(
          takeUntil(this.destroy),
          mergeMap((response) => {
            if (this.isEdit) {
              return of(true);
            }
            return this.serviceApiService.connectCamera(this.serviceId + 1111111, response.id).pipe(
              map(() => true),
              catchError((err) => {
                this.snackbar.showMessage(parseError(err));
                this.showLoading = false;
                return of(null);
              }),
            );
          }),
          catchError((err) => {
            this.snackbar.showMessage(parseError(err));
            this.showLoading = false;
            return of(null);
          }),
        )
        .subscribe((response) => {
          if (response) {
            this.snackbar.showMessage(
              this.isEdit ?
                this.translateService.instant('apartments_video_surveillance.popup.editing_camera_success') :
                this.translateService.instant('apartments_video_surveillance.popup.creating_camera_success'),
              'success'
            );
            this.showLoading = false;
            this.dynamicDialogRef.close({formData: this.addCameraForm.getRawValue()});
          }
        });
    }
  }

  public onHowToConfigureAction(): void {
    window.open(ApartmentVideoCameraConnect.HowToConnect, '_blank');
  }

  private simplePhoneValidator(): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const value = control.value.toLowerCase();
      const pattern = new RegExp(/^((http)|(https)|(rtp)|(rtsp)|(udp)):\/\//gm);
      return value !== ApartmentVideoCameraConnect.LinkPrefix && pattern.test(value) ? null : {error: true};
    };
  }
}
