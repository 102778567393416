import { ActionReducerMap } from '@ngrx/store';
import { VideoPlayerActions, VideoPlayerActionTypes } from './video-player.actions';
import { videoPlayerInitialState, VideoPlayerModuleState, VideoPlayerState } from './video-player.state';

function reducer(state = videoPlayerInitialState, action: VideoPlayerActions): VideoPlayerState {
    switch (action.type) {
        case VideoPlayerActionTypes.VideoPlayerGetPlaylists: {
            return {
                ...state
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetPlaylistsSuccess: {
            return {
                ...state,
                playlists: action.response
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetPlaylistsFailure: {
            return {
                ...state
            };
        }

        case VideoPlayerActionTypes.VideoPlayerCreateVideoshotTaskSuccess: {
            return {
                ...state,
                videoshotPopupState: 'download'
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetVideoshot: {
            return {
                ...state,
                videoshotError: null
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetVideoshotSuccess: {
            return {
                ...state,
                videoshotError: null
            };
        }

        case VideoPlayerActionTypes.VideoPlayerGetVideoshotFailure: {
            return {
                ...state,
                videoshotError: action.error
            };
        }

        case VideoPlayerActionTypes.VideoPlayerInitVideoshotState: {
            return {
                ...state,
                videoshotPopupState: 'create'
            };
        }

        case VideoPlayerActionTypes.VideoPlayerSelectCurrentSource: {
            return {
                ...state,
                currentSource: action.source
            };
        }

        case VideoPlayerActionTypes.VideoPlayerClearState: {
            return videoPlayerInitialState;
        }

        default: {
            return {
                ...state
            };
        }
    }
}

export const videoPlayerReducer: ActionReducerMap<VideoPlayerModuleState> = {
    videoPlayer: reducer
};
