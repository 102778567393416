import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/shared/components/snackbar/snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  private readonly defaultHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private readonly defaultVerticalPosition: MatSnackBarVerticalPosition = 'top';
  private config: MatSnackBarConfig;
  private snackbarRef: MatSnackBarRef<SnackbarComponent>;

  constructor(private snackBar: MatSnackBar) {
    this.initSnackbar();
  }

  showMessage(message: string, action?: 'error' | 'info' | 'success') {
    if (this.snackbarRef && this.config.data['action'] === 'error') {
      return;
    }

    this.config.duration = action === 'success' ? 4000 : 0;

    this.config.data = {
      errorText: message,
      action: action ?? 'error',
      onDismiss: () => {
        this.snackbarRef.dismiss();
        this.snackbarRef = null;
      }
    };

    this.snackbarRef = this.snackBar.openFromComponent(SnackbarComponent, this.config);
  }

  showAction(message: string, action: 'error' | 'info' | 'success', btnText: string, cb: Function) {
    this.config.data = {
      errorText: message,
      action: action ?? 'error',
      btnText,
      onDismiss: () => cb(true)
    };
    this.config.duration = 0;

    this.snackBar.openFromComponent(SnackbarComponent, this.config);
  }

  private initSnackbar() {
    this.config = new MatSnackBarConfig();
    this.config.verticalPosition = this.defaultVerticalPosition;
    this.config.horizontalPosition = this.defaultHorizontalPosition;
    this.config.duration = 0;
  }
}
